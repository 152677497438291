import { Injectable } from '@angular/core';
import { StorageService } from '../services/storage.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UserService {

 public apiURL: string = (location.host === "localhost:8100") ? 'http://curtisdesign/app/' : 'https://app.connectmyclub.co.uk/app/';
 // public apiURL: string = 'https://app.connectmyclub.co.uk/app/';

  renew_membership = false;
  membershiNewMembers = false;
  
  clubDetails;
  club_subscription
  jwttoken:string;
  public user_id:string;

  multiClubs: boolean;
  public club_name = "connectMyClub";
  public club_colors;
  club_logo
  primary_color;
  primary_color_rgb;
  secondary_color;
  secondary_color_rgb;
  tertiary_color;
  tertiary_color_rgb;
    
  userDetails = [];
  subject: any;


  public club_id = 0;
  public isAdmin: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public hasAssociations: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  
  public isUpdateAvailable: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public showMembershipForm: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isRunLeader: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public inAppSessions: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private newRoute: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public groupCancelled: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public basketUpdated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public walletUpdated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public trainingWalletLive: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public sideBarMenu: BehaviorSubject<Array<any>> =  new BehaviorSubject<any>(['blank']); 
  public readySteady: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public newAnnouncement: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public newMessageBoard: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public newDM: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  public newGroupMsg: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public lastCommsCheck: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public message_board_comment_update: BehaviorSubject<Array<number>> = new BehaviorSubject<[]>(null); 
  public training_group_update: BehaviorSubject<number> = new BehaviorSubject<number>(null); 
  public training_group_location_update: BehaviorSubject<Array<any>> = new BehaviorSubject<[]>(null); 
  public newOffer: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public newComp: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public eventsCount: BehaviorSubject<any> = new BehaviorSubject<any>(0);
  public avatar: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public clubSubscription:  BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false); 

  public showUserPinInput: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  //public pinCorrect: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public multiClubMember: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public clubID:  BehaviorSubject<any> = new BehaviorSubject<any>(0);
  public clubName:  BehaviorSubject<any> = new BehaviorSubject<any>("connectMyClub");


  cmcDetails = {'id':0,
                'name':'connectMyClub',
                'readySteady':'Ready, steady...',
                'subscription':false,
                'statusBarColor':'#ff7800',
                'statusBarText': 'dark'
    };



  public clubDetailsSubscription:  BehaviorSubject<Array<{}>> =  new BehaviorSubject<Array<{}>>([this.cmcDetails]); 

  constructor( public storage: StorageService,
    private router: Router ) { 
  }



  /* SET TRUE IF USER IS A CLUB LEADER */
  updateRunLeaderStatus(data): void {
    this.isRunLeader.next(data);
  }
  getRunLeaderStatus(): Observable<boolean> {
    return this.isRunLeader;
  }

  /* SET TRUE IF A NEW ROUTE HAS BEEN CREATED */
  newRouteAdded(data): void {
    this.newRoute.next(data);
  }
  getNewRouteAdded(): Observable<boolean> {
    return this.newRoute;
  }


  


async getUser() {

    let user;
             await this.storage.getObject('clubDetails').then((data: any) => {

              if (data) {

                console.log("USER DATA")
                console.log(data)

                this.clubDetails = data;

                user = data;

                this.user_id = this.clubDetails['user_id'];
                
                let subscription = this.clubDetails['club_subscription'];

                this.clubSubscription.next(subscription)

                let timestamp = Date.now()
                
                let avatar = this.apiURL+'avatars/'+this.user_id+'.png?'+timestamp
                this.avatar.next(avatar)
                this.userDetails['avatar'] = avatar

                this.jwttoken = this.clubDetails['jwttoken'];
                this.multiClubs = this.clubDetails['multiClubs'];
                
                this.club_id = this.clubDetails['club_id'];

    
                this.clubID.next(this.club_id);
                this.club_name = this.clubDetails['club_name'];  
                this.clubName.next(this.club_name);

                this.club_colors = this.clubDetails['colors']['color1'];
 
                this.userDetails['user_id'] = this.user_id.toString();
                this.userDetails['club_id'] = this.club_id.toString();
                this.userDetails['jwttoken'] = this.jwttoken;
                this.userDetails['multiClubs'] = this.multiClubs;

               this.primary_color = this.clubDetails['colors']['primary'];
               this.primary_color_rgb = this.clubDetails['colors']['primary_color_rgb'];
               this.secondary_color = this.clubDetails['colors']['secondary'];
               this.secondary_color_rgb = this.clubDetails['colors']['secondary_color_rgb'];
               this.tertiary_color = this.clubDetails['colors']['tertiary'];
               this.tertiary_color_rgb = this.clubDetails['colors']['tertiary_rgb'];

                /* if a tertiary color isn't set them make primary color the terriary color */
               if( this.tertiary_color == null ){
                this.tertiary_color = this.primary_color;
                this.tertiary_color_rgb = this.primary_color_rgb;
               }

               document.documentElement.style.setProperty(`--ion-color-primary`, this.primary_color); 
               document.documentElement.style.setProperty(`--ion-color-base-rgb`, this.primary_color_rgb); 
               document.documentElement.style.setProperty(`--ion-color-primary-shade`, this.primary_color_rgb); 
               document.documentElement.style.setProperty(`--ion-color-primary-tint`, this.primary_color_rgb); 

               document.documentElement.style.setProperty(`--ion-color-primary-contrast`, this.secondary_color); 
               document.documentElement.style.setProperty(`--ion-color-contrast-rgb`, this.secondary_color_rgb); 

               document.documentElement.style.setProperty(`--ion-color-tertiary`, this.tertiary_color);
               document.documentElement.style.setProperty(`--ion-color-tertiary-rgb`, this.tertiary_color_rgb); 
               document.documentElement.style.setProperty(`--ion-color-tertiary-shade`, this.tertiary_color_rgb); 
               document.documentElement.style.setProperty(`--ion-color-tertiary-tint`, this.tertiary_color_rgb);

               document.documentElement.style.setProperty(`--ion-color-tertiary-contrast`, this.secondary_color); 
               document.documentElement.style.setProperty(`--ion-color-tertiary-contrast-rgb`, this.secondary_color_rgb); 

               

               let sidebar = []

               if(this.clubDetails.sidebar){
                sidebar = this.clubDetails.sidebar
               }
           
              this.sideBarMenu.next(sidebar);
              this.readySteady.next(this.clubDetails.ready_steady)


 let cmcDetails = [];

  cmcDetails['id'] = this.club_id
  cmcDetails['name'] = this.club_name
  cmcDetails['readySteady'] = this.clubDetails.ready_steady
  cmcDetails['subscription'] = subscription
  cmcDetails['statusBarColor'] = this.primary_color
  cmcDetails['statusBarText'] = this.clubDetails['colors']['status_bar']



                  this.clubDetailsSubscription.next(cmcDetails)
                  
               /* check if membership form needs to be shown */

               this.membershiNewMembers = this.clubDetails['membership_new_members']
     // console.log(this.router.url);
      let membership_form_completed = this.clubDetails['membership_form_completed']
                if( this.membershiNewMembers == true && membership_form_completed == false){
              

               //  console.log(this.router.url);
                  let pageURL = this.router.url
                  this.showMembershipForm.next(true);
                  let passURLS = ["/app/tabs/my-details-account","/app/tabs/my-details-personal","/app/tabs/my-details-ice","/app/tabs/code-of-conduct","/app/tabs/user-settings","/app/tabs/about-licences","/app/tabs/about-terms","/app/tabs/about-privacy","/app/tabs/switch-clubs"];

                  if( pageURL.includes("club-subscription") ){
                    passURLS.push(pageURL)
                  }
//console.log(this.router.url)
                  if( !passURLS.includes(this.router.url) ){
 this.router.navigate(['app/tabs/membership'], { replaceUrl: true });
                  }

                  
                  
                }/**/
 
              }
            });
        return user; 
    
}




}

